<template>
  <NavQuestion
    :heading="heading"
    :number="'6'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <textarea
        v-model="wishes"
        class="input-text input-standard-white fadeIn-2"
        placeholder="e.g. I would like my ashes to be scattered, and instead of flowers, I would prefer for donations be made to Cancer Research UK"
        rows="4"
        cols="5"
      >
      </textarea>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/funeral/wishes_option'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'

export default {
  name: 'FuneralWishes',
  components: {
    NavQuestion,
    NavQuestionButtons
  },
  watch: {
    funeral: {
      immediate: true,
      handler(value) {
        this.wishes = value.funeral_wishes
      }
    }
  },
  computed: {
    isValidated() {
      return true
    },
    heading() {
      return 'What are your wishes?'
    },
    subHeading() {
      return (
        ' If you would like to be buried, where would you like to have your grave? If you would like to be ' +
        'cremated, what would you like to be done with your ashes and where? Where would you like the service to be ' +
        'held? Would you like flowers at your funeral or would you prefer donations to be sent and if so, where to? ' +
        'Information added here will be included word-for-word in your Will.'
      )
    },
    forwardTo() {
      return '/funeral/check_and_confirm'
    },
    funeral() {
      return this.$store.getters.funeral
    }
  },
  data() {
    return {
      wishes: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  methods: {
    save() {
      if (this.funeral) {
        var wishesModified = this.wishes
        if (wishesModified[wishesModified.length - 1] === '.')
          wishesModified = wishesModified.slice(0, -1)
        this.$store.commit('funeral', {
          funeral_wishes: wishesModified
        })
      }
    }
  }
}
</script>

<style></style>
